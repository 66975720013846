import styled from 'styled-components'
import { css } from 'styled-components'

const Input = styled.input`
  /* width: 23.4375rem; */
  /* height: 3.5rem; */
  /* max-width: 90vw; */
  transition: all 200ms;
  box-sizing: border-box;
  /* background: ${props => props.theme.core.background.tertiary}; */
  font-size: 16px;
  line-height: 28px;
  /* padding: ${props =>
    `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`}; */
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 8px 12px;
  border-radius: 5px;
  outline: none;
  transition: all 100ms ease-in-out;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #AFAFAF;
  }
  :-ms-input-placeholder {
     color: #AFAFAF;
  }
  :focus {
    /* background: ${props => props.theme.colors.white}; */
    border: 1px solid ${props => props.theme.colors.black} !important;
  }
  :hover {
    border: 1px solid #e2e2e2;
    @media (max-width: 769px) {
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
`

export default Input
