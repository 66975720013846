import { useEffect, useState } from 'react'

const useOnEnterEventListener = (condition = false, onEnter) => {
  useEffect(() => {
    const listener = event => {
      if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        condition
      ) {
        onEnter()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [condition, onEnter])
}

export default useOnEnterEventListener
