import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { noop } from 'lodash-es'

const MobileTopBarContainer = styled.div`
  /* height: 56px; */
  border-bottom: 0.3px solid #dbdbdb;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

const BackIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 18L9 12L15 6"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

const BackContainer = styled.div`
  display: grid;
  place-content: center;
`

const MobileTopBar = ({ children = '', backPath = '', onBack }) => {
  const hasBack = backPath || onBack
  return (
    <MobileTopBarContainer
      style={{
        justifyContent: hasBack ? 'space-between' : 'center',
      }}
    >
      {hasBack && (
        <BackContainer
          onClick={event => {
            if (backPath) {
              navigate(backPath)
            } else if (onBack) {
              onBack(event)
            }
          }}
        >
          <BackIcon />
        </BackContainer>
      )}
      {children}
      {hasBack && <div style={{ width: '24px' }} />}
    </MobileTopBarContainer>
  )
}

export default MobileTopBar
