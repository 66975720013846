import React from 'react'
import styled, { css } from 'styled-components'
import Box from './box'
import BackIcon from '../assets/svg/back.svg'
import { navigate } from 'gatsby'

const Container = styled.div`
  position: fixed;
  height: 100vh;
  display: grid;
  place-content: center;
  left: 20px;
  @media (max-width: 769px) {
    display: none;
  }
`

const StepContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* color: #000000; */
  display: flex;
  cursor: default;
  margin: 2px 0px;
  transition: all 200ms;
  ${props =>
    props.hiddenText
      ? css`
          color: #ffffff;
          &:hover {
            color: #cbcbcb;
          }
        `
      : css`
          color: #000000;
        `}/* cursor: pointer; */
`

const BackContainer = styled.div`
  position: fixed;
  top: 32px;
  left: 32px;
  cursor: pointer;
`

const StepBullet = ({ isFilled = false }) => (
  <Box
    style={{
      display: 'grid',
      placeContent: 'center',
    }}
  >
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="4"
        r="4"
        fill={isFilled ? 'black' : 'rgba(196, 196, 196, 0.4)'}
      />
    </svg>
  </Box>
)

const AuthSidebar = ({ currentStepIndex = 0, isSignIn = false }) => {
  const steps = ['Verify', 'Profile', 'Workspace', 'Streams', 'Book a call']
  return (
    <Container>
      {isSignIn || currentStepIndex === 0 ? (
        <BackContainer
          onClick={() => {
            navigate('/')
          }}
        >
          <BackIcon />
        </BackContainer>
      ) : null}
      {!isSignIn && (
        <Box>
          {steps.map((step, index) => (
            <StepContainer key={index} hiddenText={currentStepIndex !== index}>
              <StepBullet isFilled={index <= currentStepIndex} />
              <Box style={{ marginLeft: '8px' }}>{step}</Box>
            </StepContainer>
          ))}
        </Box>
      )}
    </Container>
  )
}

export default AuthSidebar
