import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
// import { H1 } from '..'
import Input from '../../components/input'
import axios from 'axios'
import asyncLocalStorage from '../../utils/asyncLocalStorage'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import Layout from '../../components/layout'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { emailRegex } from '../../utils/regex'
import checkUtmParams from '../../utils/checkUtmParams'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import AuthSidebar from '../../components/authSidebar'
import MobileTopBar from '../../components/mobileTopBar'
import { isMobileView } from '../../utils/isMobileView'
const SignupContainer = ({ location }) => (
  <AuthProvider>
    <Signup
      isSessionExpired={location && location.state && location.state.error}
    />
  </AuthProvider>
)

export const Container = styled.div`
  display: grid;
  place-content: center;
  /* flex-direction: column; */
  width: 100%;
  height: 100vh;
  margin: auto;
  /* margin-top: 56px; */
  /* margin-bottom: 93px; */
  align-items: center;
  @media (min-width: 769px) {
    /* margin-left: 80px; */
    align-items: flex-start;
  }
`

const InputContainer = styled.div`
  /* padding-top: 32px; */
  display: flex;
  justify-content: center;
  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`

export const Button = styled.button`
  width: fit-content;
  /* height: 64px;
  left: 0px;
  top: 0px; */
  transition: all 200ms;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  /* text-transform: capitalize; */
  border: none;
  border-radius: 5px;
  background: black;
  cursor: pointer;
  /* margin-bottom: 12px; */
  padding: 10px 20px;
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  &:hover {
    background: ${props => (props.disabled ? '' : '#2e2e2e')};
  }
  @media (max-width: 769px) {
    &:hover {
      ${props =>
        props.disabled
          ? css`
              background-color: #afafaf;
            `
          : css`
              background-color: #000000;
            `};
    }
  }
  ${props =>
    props.disabled
      ? css`
          background-color: #afafaf;
          /* color: #afafaf; */
          cursor: not-allowed;
        `
      : ''};
`

export const CopySubText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
  color: #545454;
  @media (max-width: 769px) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;
  }
`

export const MiscText = styled.span`
  /* font-family: ${props => props.theme.typography.fontFamilyGTMono}; */
  line-height: 22px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: #545454;
  @media (max-width: 769px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const MiscTextLink = styled.a`
  /* font-family: ${props => props.theme.typography.fontFamilyGTMono}; */
  line-height: 22px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: black;
  text-decoration: none;
  &:hover {
    color: black !important;
  }
  @media (max-width: 769px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const ErrorBanner = styled.div`
  /* background-color: #fbefee; */
  /* font-family: ${props => props.theme.typography.fontFamilyGTMono}; */
  font-size: 14px;
  line-height: 22px;
  color: #EE0000;
  /* border: 1px solid #ec666a; */
  /* padding: 12px; */
`

export const WarningBanner = styled.div`
  /* background-color: #fbf5ff; */
  /* font-family: ${props => props.theme.typography.fontFamilyGTMono}; */
  font-size: 14px;
  line-height: 22px;
  color: #9601ff;
  /* border: 1px solid #9601ff; */
  /* padding: 12px; */
`

export const SuccessBanner = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #47cd43;
`

export const AuthTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 769px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const MobileAuthContainer = styled.div`
  button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
  input,
  select {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 28px;
  }
  .auth-mobile-body {
    padding: 40px 24px 24px 24px;
    overflow-y: scroll;
  }
`

const Signup = ({ isSessionExpired }) => {
  const {
    setLoading,
    checkEmailMxRecordWithDebounce,
    navigateAuth,
  } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [emailWarning, setEmailWarning] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useEffect(() => {
    if (isSessionExpired) {
      setError('Session Expired. Sign-up again')
    }
  }, [isSessionExpired])

  const debouncedEmailCheck = value => {
    if (!value) return
    return new Promise((res, rej) => {
      checkEmailMxRecordWithDebounce(value, res, rej, setEmailWarning)
    })
  }

  useHotjarScript()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(config.backendUri + '/auth/sign-up', {
        email,
      })
      if (result.data.isEmailInvalid) {
        setLoading(false)
        setError(`
          Please use your work email to claim your own workspace or join an
          existing one by logging in.
        `)
      } else {
        await asyncLocalStorage.setItem('session', result.data.session)
        await axios.post(
          config.backendUri + '/auth/confirm-email',
          {
            new: true,
          },
          {
            headers: {
              Authorization: `Bearer ${result.data.session}`,
            },
          }
        )

        navigateAuth('/signup/verify')
      }
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useOnEnterEventListener(emailRegex.test(email), () => {
    handleSubmit()
  })

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Sign up" />
        <MobileTopBar backPath="/">Sign Up</MobileTopBar>
        <div className="auth-mobile-body">
          <AuthTitle>Welcome to Pulse</AuthTitle>
          <CopySubText>
            Communicate better by sharing and discussing asynchronously in one,
            easy spot.
          </CopySubText>
          <InputContainer>
            <Input
              style={{
                width: '100%',
                height: '40px',
                fontSize: '14px',
                lineHeight: '28px',
              }}
              placeholder="your@company.com"
              onChange={({ target: { value } }) => {
                setError(null)
                setEmailWarning(null)
                setEmail(value)
                debouncedEmailCheck(value)
              }}
              value={email}
            />
          </InputContainer>
          <Button
            disabled={!emailRegex.test(email)}
            onClick={() => handleSubmit()}
          >
            {/* <Box style={{ height: '16px' }}>That's my email</Box> */}
            Continue
          </Button>
          {error && (
            <ErrorBanner style={{ marginTop: '24px' }}>{error}</ErrorBanner>
          )}
          {emailWarning && (
            <WarningBanner style={{ marginTop: '24px' }}>
              Are you sure that address is typed correctly? If there are no
              mistakes, carry on!
            </WarningBanner>
          )}
          <Box
            style={{
              marginTop: '166px',
              // width: '270px',
              // height: '28px',
              display: 'flex',
              // paddingBottom: '24px',
              // borderBottom: '1px solid #E2E2E2',
              // width: '400px',
            }}
          >
            <MiscText disabled={!emailRegex.test(email)}>
              Already have an account?{' '}
            </MiscText>
            <MiscTextLink href="/login" style={{ marginLeft: '5px' }}>
              Log in
            </MiscTextLink>
          </Box>
          {/* <Box style={{ marginTop: '24px' }}>
            <CopySubText
              style={{
                color: '#757575',
              }}
            >
              By proceeding you accept and agree to {'  '}
              <MiscTextLink href="/terms" style={{ color: '#545454' }}>
                Pulse's Terms of Service
              </MiscTextLink>{' '}
              and{' '}
              <MiscTextLink href="/privacy-policy" style={{ color: '#545454' }}>
                Privacy Policy
              </MiscTextLink>
            </CopySubText>
          </Box> */}
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Sign up" />
      <Container>
        <AuthSidebar currentStepIndex={0} />
        <Box style={{ marginTop: '80px' }}>
          <AuthTitle
            style={{
              fontSize: '20px',
              lineHeight: '24px',
              color: 'black',
              marginBottom: '16px',
            }}
          >
            Welcome to Pulse
          </AuthTitle>
          <CopySubText style={{ width: '400px' }}>
            Communicate better by sharing and discussing asynchronously in one,
            easy spot.
          </CopySubText>
          <InputContainer>
            <Input
              placeholder="your@company.com"
              onChange={({ target: { value } }) => {
                setError(null)
                setEmailWarning(null)
                setEmail(value)
                debouncedEmailCheck(value)
              }}
              value={email}
              style={{ width: '400px' }}
            />
          </InputContainer>
        </Box>
        <Box style={{ marginTop: '24px', width: '400px' }}>
          <Button
            // style={{ height: '48px', width: '100%' }}
            disabled={!emailRegex.test(email)}
            onClick={() => handleSubmit()}
          >
            {/* <Box style={{ height: '16px' }}>That's my email</Box> */}
            Continue
          </Button>
          {error && (
            <ErrorBanner style={{ marginTop: '16px' }}>{error}</ErrorBanner>
          )}
          {emailWarning && (
            <WarningBanner style={{ marginTop: '16px' }}>
              Are you sure that address is typed correctly? If there are no
              mistakes, carry on!
            </WarningBanner>
          )}
          <Box
            style={{
              marginTop: '16px',
              // width: '270px',
              // height: '28px',
              display: 'flex',
              paddingBottom: '24px',
              borderBottom: '1px solid #E2E2E2',
              width: '400px',
            }}
          >
            <MiscText disabled={!emailRegex.test(email)}>
              Already have an account?{' '}
            </MiscText>
            <MiscTextLink href="/login" style={{ marginLeft: '5px' }}>
              Log in
            </MiscTextLink>
          </Box>
          <Box style={{ marginTop: '24px' }}>
            <CopySubText
              style={{
                color: '#757575',
              }}
            >
              By proceeding you accept and agree to {'  '}
              <MiscTextLink href="/terms" style={{ color: '#545454' }}>
                Pulse's Terms of Service
              </MiscTextLink>{' '}
              and{' '}
              <MiscTextLink href="/privacy-policy" style={{ color: '#545454' }}>
                Privacy Policy
              </MiscTextLink>
            </CopySubText>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default SignupContainer
