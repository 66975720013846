import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import { H1 } from '..'
import axios from 'axios'
import { Fragment, useRef } from 'react'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { get } from 'lodash'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import generateMagicLink from '../../utils/handleLogin'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MiscText,
  MiscTextLink,
  MobileAuthContainer,
  SuccessBanner,
} from '../signup'
import AuthSidebar from '../../components/authSidebar'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'
import Loader from '../../components/loader'

const WorkspaceContainer = () => (
  <AuthProvider isSignIn>
    <ClaimWorkspace />
  </AuthProvider>
)

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

const MainBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 137px;
`

const WorkspacesBox = styled.div`
  /* height: 520px; */
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-content: center;
`

const SecondaryBox = styled.div`
  width: 600px;
  /* background-color: #f6f6f6; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin-top: 74px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  @media (max-width: 769px) {
    width: 100%;
  }
`

const WorkspacesRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 12px;
  margin-bottom: 12px;
`

const WorkspaceName = styled.div`
  /* font-family: ${props => props.theme.typography.fontFamilyGT}; */
  /* font-weight: bold; */
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`

const MainWorkspacePill = styled.div`
  /* margin-top: 32px; */
  /* width: 100%; */
  height: 47px;
  width: 376px;
  @media (max-width: 769px) {
    width: 100%;
  }
  /* background-color: rgba(246, 246, 246, 0.5); */
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-family: ${props => props.theme.typography.fontFamilyGT}; */
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  background: linear-gradient(156.21deg, #b0ed6a -3.25%, #88fcd3 92.16%);
`

const WorkspaceMemberCountText = styled.div`
  /* font-family: ${props => props.theme.typography.fontFamilyGTMono}; */
  color: #757575;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 24px; */
  color: #757575;
`

const FlexColumnBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const WorkspaceDivider = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-top: 12px;
  margin-bottom: 12px;
`

const WorkspacesList = ({ headerText, workspaces = [], handleJoin }) => (
  <WorkspacesBox>
    <AuthTitle style={{ marginBottom: '16px' }}>{headerText}</AuthTitle>
    <CopySubText
    // style={{
    //   marginTop: '16px',
    //   fontSize: '14px',
    //   lineHeight: '22px',
    //   textAlign: 'center',
    // }}
    >
      You’ve access to the following workspaces.
    </CopySubText>
    <Box style={{ marginTop: '24px' }}>
      {workspaces.map((tenant, index) => (
        <Fragment key={tenant.id}>
          <WorkspacesRow>
            <Box style={{ display: 'flex' }}>
              {tenant.picture && tenant.picture.url ? (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginRight: '12px',
                  }}
                >
                  <img
                    src={tenant.picture.url}
                    width="36"
                    height="36"
                    style={{
                      borderRadius: '150px',
                    }}
                  />
                </Box>
              ) : null}
              <Box>
                <WorkspaceName>{tenant.subdomain}.pulse.so</WorkspaceName>
                <WorkspaceMemberCountText>
                  {tenant.memberCount} members
                </WorkspaceMemberCountText>
              </Box>
            </Box>
            <Box>
              <Button
                style={{
                  // height: '36px',
                  // width: '80px',
                  // fontSize: '12px',
                  borderRadius: '999px',
                  padding: '8px 20px',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
                onClick={() => {
                  handleJoin(tenant.id)
                }}
              >
                Join
              </Button>
            </Box>
          </WorkspacesRow>
          {workspaces.length - 1 !== index && <WorkspaceDivider />}
        </Fragment>
      ))}
    </Box>
  </WorkspacesBox>
)

const MainWorkspaceContainer = ({
  headerText,
  copySubText,
  buttonText,
  workspaceName,
  onClick,
  isDisabled = false,
  isInSecondaryBox = false,
  isMobileView = false,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        width: '100%',
        textAlign: isInSecondaryBox ? 'center' : 'unset',
        alignItems: isInSecondaryBox ? 'center' : 'unset',
      }}
    >
      <AuthTitle
        style={{
          marginBottom: '16px',
        }}
      >
        {headerText}
      </AuthTitle>
      <CopySubText
        style={
          isMobileView
            ? {}
            : {
                // margin: '0px 98px',
                // textAlign: 'center',
                // marginTop: '16px',
                width: '400px',
                // marginTop: '16px',
              }
        }
      >
        {copySubText}
      </CopySubText>
      {/* <MainWorkspacePill>{workspaceName}</MainWorkspacePill> */}
      <Button disabled={isDisabled} onClick={onClick}>
        {buttonText}
      </Button>
      <MiscText style={{ marginTop: '16px' }}>
        Need help?{'  '}
        <MiscTextLink href="mailto:support@pulse.so">Contact us</MiscTextLink>
      </MiscText>
    </Box>
  )
}

const USER_STATUS = {
  ACTIVE: 'active',
  INVITED: 'invited',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted',
}

const ClaimWorkspace = () => {
  const {
    userSession,
    token,
    setLoading: setAuthLoading,
    workspaces = [],
  } = useContext(AuthContext)
  const [otherWorkspaces, setOtherWorkspaces] = useState([])
  const [mainWorkspace, setMainWorkspace] = useState(null)
  const [userCanCreateWorkspace, setUserCanCreateWorkspace] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [isComponentLoading, setIsComponentLoading] = useState(false)
  const isMounted = useRef(false)

  useEffect(() => {
    checkUtmParams()
  }, [])

  const initialize = async () => {
    setAuthLoading(true)
    setIsComponentLoading(true)
    try {
      if (userSession) {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        // check if user email is company email
        const isCompanyEmailResult = await axios.post(
          `${config.backendUri}/auth/check-email`,
          {
            email: userSession.email,
          },
          axiosConfig
        )
        const isUserEmailCompanyEmail = !!isCompanyEmailResult.data.isValid
        let mainWorkspace = null
        let canCreateWorkspace = false
        // get main workspace by email domain using the user session email
        if (isUserEmailCompanyEmail) {
          try {
            const mainWorkspaceResult = await axios.get(
              `${config.backendUri}/auth/session/tenants/main`,
              axiosConfig
            )
            mainWorkspace = mainWorkspaceResult.data
          } catch (error) {
            if (error.response && error.response.status === 404) {
              canCreateWorkspace = true
            }
          }
        }

        setMainWorkspace(mainWorkspace)
        setUserCanCreateWorkspace(canCreateWorkspace)
        setOtherWorkspaces(
          mainWorkspace
            ? workspaces.filter(data => data.id !== mainWorkspace.id)
            : workspaces
        )
      }
      setAuthLoading(false)
      setIsComponentLoading(false)
    } catch (error) {
      setAuthLoading(false)
      setIsComponentLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useEffect(() => {
    if (isMounted.current && !isComponentLoading) {
      initialize()
    }
  }, [userSession, workspaces])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const requestToJoin = async () => {
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session/tenants/main/request-to-join`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (result.data.requestAlreadySent) {
        setSuccess(`We already notified a workspace admin of your request. You’ll get
        an email as soon as they approve it.`)
      } else {
        setSuccess(`We just notified a workspace admin of you request. You’ll get
        an email as soon as they approve it.`)
      }
    } catch (error) {
      setError(handleAuthNetworkError(error))
    }
  }

  // const generateMagicLink = async tenantId => {
  //   return await axios
  //     .post(
  //       `${config.backendUri}/auth/session/magic-links`,
  //       {
  //         tenantId,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then(mlResult => {
  //       console.log(mlResult.data.magicLink)
  //       return mlResult.data.magicLink
  //     })
  //     .catch(mlError => {
  //       console.error(mlError)
  //       throw new Error({
  //         response: { data: { message: 'Cannot generate Magic Link' } },
  //       })
  //     })
  // }

  const createUserPulseInvitation = async () => {
    try {
      await axios.post(`${config.backendUri}/auth/session/create-user`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const mlUrl = await generateMagicLink(token, mainWorkspace.id)
      setSuccess(
        `You've been added to the workspace, we're redirecting you to the workspace...`
      )
      window.location.href = mlUrl
    } catch (error) {
      if (
        get(error, 'response.status') === 409 &&
        get(error, 'response.data.userStatus')
      ) {
        const userStatus = error.response.data.userStatus
        if (
          [USER_STATUS.DEACTIVATED, USER_STATUS.DELETED].includes(userStatus)
        ) {
          setError(`Your account has been deactivated or deleted.`)
        } else if (
          [USER_STATUS.ACTIVE, USER_STATUS.INVITED].includes(userStatus)
        ) {
          // Check if the error is due to the magic link system
          if (
            get(error, 'response.data.message') === 'Cannot generate Magic Link'
          ) {
            setError(handleAuthNetworkError(error))
          } else {
            // // redirect
            // window.location.href = `https://${mainWorkspace.subdomain}.pulse.so/`
            // Generate Magic link and redirect the user!
            const mlUrl = await generateMagicLink(token, mainWorkspace.id)
            window.location.href = mlUrl
          }
        }
      } else {
        setError(handleAuthNetworkError(error))
      }
    }
  }

  useHotjarScript()
  const joinOtherWorkspace = async tenantId => {
    try {
      const mlUrl = await generateMagicLink(token, tenantId)
      window.location.href = mlUrl
    } catch (error) {
      setError(handleAuthNetworkError(error))
    }
  }

  if (!userSession) return null

  if (isComponentLoading) {
    return (
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        boxSizing="border-box"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        zIndex="100"
        backgroundColor="white"
        top="0"
        bottom="0"
      >
        <Box fontSize="3rem">
          <Loader dark />
        </Box>
      </Box>
    )
  }

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Login to your workspace" />
        <MobileTopBar backPath="/">Login</MobileTopBar>
        <div className="auth-mobile-body">
          <MainBox>
            {mainWorkspace ? (
              <>
                <MainWorkspaceContainer
                  isMobileView
                  buttonText={
                    mainWorkspace.isMember ||
                    !mainWorkspace.workspaceRequireRequestToJoin
                      ? 'Get In There'
                      : 'Request To Join'
                  }
                  copySubText={
                    <>
                      There’s already a workspace waiting for you:{' '}
                      <strong>{mainWorkspace.subdomain + '.pulse.so'}</strong>.
                      Join your team in no time.
                    </>
                  }
                  headerText={'Enter your company workspace!'}
                  // workspaceName={mainWorkspace.subdomain + '.pulse.so'}
                  onClick={() => {
                    if (
                      mainWorkspace.workspaceRequireRequestToJoin &&
                      !mainWorkspace.isMember
                    ) {
                      requestToJoin()
                    } else {
                      createUserPulseInvitation()
                    }
                  }}
                  isDisabled={!!success}
                />
                {success ? (
                  <Box style={{ marginTop: '16px', width: '376px' }}>
                    <SuccessBanner>{success}</SuccessBanner>
                  </Box>
                ) : null}
                {otherWorkspaces.length ? (
                  <SecondaryBox>
                    <WorkspacesList
                      headerText={'Or log in here'}
                      workspaces={otherWorkspaces}
                      handleJoin={joinOtherWorkspace}
                    />
                  </SecondaryBox>
                ) : null}
              </>
            ) : (
              <>
                {otherWorkspaces.length ? (
                  <>
                    <WorkspacesList
                      headerText={'Log in here'}
                      workspaces={otherWorkspaces}
                      handleJoin={joinOtherWorkspace}
                    />
                    {userCanCreateWorkspace && (
                      <SecondaryBox>
                        <MainWorkspaceContainer
                          isInSecondaryBox
                          isMobileView
                          headerText={'Create your company workspace'}
                          buttonText={'Create workspace'}
                          copySubText={
                            <>
                              We couldn't find a workspace associated to{' '}
                              <span style={{ color: '#000000' }}>
                                {userSession.email.split('@')[1].split('.')[0] +
                                  '.pulse.so'}
                              </span>{' '}
                              . Create it now.
                            </>
                          }
                          workspaceName={
                            userSession.email.split('@')[1].split('.')[0] +
                            '.pulse.so'
                          }
                          onClick={() => {
                            navigate('/signup')
                          }}
                        />
                      </SecondaryBox>
                    )}
                  </>
                ) : userCanCreateWorkspace ? (
                  <MainWorkspaceContainer
                    isMobileView
                    headerText={'Create your company workspace'}
                    buttonText={'Create workspace'}
                    copySubText={
                      <>
                        We couldn't find a workspace associated to{' '}
                        <span style={{ color: '#000000' }}>
                          {userSession.email.split('@')[1].split('.')[0] +
                            '.pulse.so'}
                        </span>{' '}
                        . Create it now.
                      </>
                    }
                    workspaceName={
                      userSession.email.split('@')[1].split('.')[0] +
                      '.pulse.so'
                    }
                    onClick={() => {
                      navigate('/signup')
                    }}
                  />
                ) : (
                  <Box style={{ marginTop: '175px' }}>
                    <AuthTitle
                      style={{
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: 'black',
                        marginBottom: '0px',
                      }}
                    >
                      Sorry, you don't have a workspace yet 🙁
                    </AuthTitle>
                    <CopySubText
                      style={{
                        width: '443px',
                        height: '44px',
                        marginTop: '16px',
                      }}
                    >
                      Do you have a company email? Login with your company email
                      instead
                    </CopySubText>
                  </Box>
                )}
              </>
            )}
            {error && (
              <Box style={{ marginTop: '16px', width: '376px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </MainBox>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Login to your workspace" />
      <Container
        style={{
          height: 'unset',
        }}
      >
        <MainBox>
          <AuthSidebar isSignIn />
          {mainWorkspace ? (
            <>
              <MainWorkspaceContainer
                buttonText={
                  mainWorkspace.isMember ||
                  !mainWorkspace.workspaceRequireRequestToJoin
                    ? 'Get In There'
                    : 'Request To Join'
                }
                copySubText={
                  <>
                    There’s already a workspace waiting for you:{' '}
                    <strong>{mainWorkspace.subdomain + '.pulse.so'}</strong>.
                    Join your team in no time.
                  </>
                }
                headerText={'Enter your company workspace!'}
                // workspaceName={mainWorkspace.subdomain + '.pulse.so'}
                onClick={() => {
                  if (
                    mainWorkspace.workspaceRequireRequestToJoin &&
                    !mainWorkspace.isMember
                  ) {
                    requestToJoin()
                  } else {
                    createUserPulseInvitation()
                  }
                }}
                isDisabled={!!success}
              />
              {success ? (
                <Box style={{ marginTop: '16px', width: '376px' }}>
                  <SuccessBanner>{success}</SuccessBanner>
                </Box>
              ) : null}
              {otherWorkspaces.length ? (
                <SecondaryBox>
                  <WorkspacesList
                    headerText={'Or log in here'}
                    workspaces={otherWorkspaces}
                    handleJoin={joinOtherWorkspace}
                  />
                </SecondaryBox>
              ) : null}
            </>
          ) : (
            <>
              {otherWorkspaces.length ? (
                <>
                  <WorkspacesList
                    headerText={'Log in here'}
                    workspaces={otherWorkspaces}
                    handleJoin={joinOtherWorkspace}
                  />
                  {userCanCreateWorkspace && (
                    <SecondaryBox>
                      <MainWorkspaceContainer
                        isInSecondaryBox
                        headerText={'Create your company workspace'}
                        buttonText={'Create workspace'}
                        copySubText={
                          <>
                            We couldn't find a workspace associated to{' '}
                            <span style={{ color: '#000000' }}>
                              {userSession.email.split('@')[1].split('.')[0] +
                                '.pulse.so'}
                            </span>{' '}
                            . Create it now.
                          </>
                        }
                        workspaceName={
                          userSession.email.split('@')[1].split('.')[0] +
                          '.pulse.so'
                        }
                        onClick={() => {
                          navigate('/signup')
                        }}
                      />
                    </SecondaryBox>
                  )}
                </>
              ) : userCanCreateWorkspace ? (
                <MainWorkspaceContainer
                  headerText={'Create your company workspace'}
                  buttonText={'Create workspace'}
                  copySubText={
                    <>
                      We couldn't find a workspace associated to{' '}
                      <span style={{ color: '#000000' }}>
                        {userSession.email.split('@')[1].split('.')[0] +
                          '.pulse.so'}
                      </span>{' '}
                      . Create it now.
                    </>
                  }
                  workspaceName={
                    userSession.email.split('@')[1].split('.')[0] + '.pulse.so'
                  }
                  onClick={() => {
                    navigate('/signup')
                  }}
                />
              ) : (
                <Box style={{ marginTop: '175px' }}>
                  <AuthTitle
                    style={{
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: 'black',
                      marginBottom: '0px',
                    }}
                  >
                    Sorry, you don't have a workspace yet 🙁
                  </AuthTitle>
                  <CopySubText
                    style={{
                      width: '443px',
                      height: '44px',
                      marginTop: '16px',
                    }}
                  >
                    Do you have a company email? Login with your company email
                    instead
                  </CopySubText>
                </Box>
              )}
            </>
          )}
          {error && (
            <Box style={{ marginTop: '16px', width: '376px' }}>
              <ErrorBanner>{error}</ErrorBanner>
            </Box>
          )}
        </MainBox>
      </Container>
    </Layout>
  )
}

export default WorkspaceContainer
